import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';

import Header from './components/Header';
import Main from './components/Main';
import Footer from './components/Footer';
import './App.css';

function App() {
  return (
    <BrowserRouter>
      <ToastProvider placement="bottom-right" autoDismiss={true} autoDismissTimeout={5000}>
        <Header />
        <Main />
        <Footer />
      </ToastProvider>
    </BrowserRouter>
  );
}

export default App;
