import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import {
  Col, Image, Row, Button, InputGroup, FormControl,
} from 'react-bootstrap';
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import QuestLogo from '../assets/images/categories/quest.png';

const ItemList = (props) => {
  const { addToast } = useToasts();
  const history = useHistory();

  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [searchName, setSearchName] = useState('');

  const handleSearch = () => {
    setFilteredItems(items
      .filter((item) => item.name.toLowerCase().includes(searchName.toLowerCase()))
      .sort((a, b) => a.level - b.level));
  };

  const loadItems = async () => {
    const { data } = await axios.get(`./assets/data/${props.jsonFileName}.json`);
    setItems(data);
    setFilteredItems(data
      .filter((item) => item.name.toLowerCase()
        .includes(searchName.toLowerCase())).sort((a, b) => a.level - b.level));
  };

  useEffect(async () => { await loadItems(); }, []);
  useEffect(() => { handleSearch(); }, [searchName]);

  const columns = [
    {
      dataField: 'image',
      text: 'Imagine',
      isDummyField: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => {
        if (props.jsonFileName === 'quests') {
          return <Image className="item-icon" src={QuestLogo} />;
        }

        let zerosImgPath = '';
        if (row.id.length <= 5) {
          zerosImgPath = '0'.repeat(5 - row.id.length);
        }
        return <Image className="item-icon" src={`./assets/images/icons/${zerosImgPath}${row.id}.png`} />;
      },
    },
    { dataField: 'name', text: 'Name' },
    props.jsonFileName !== 'chests' && props.jsonFileName !== 'quests' && props.jsonFileName !== 'pets' && props.jsonFileName !== 'mounts' && { dataField: 'level', text: 'Level' },
  ];

  const tableRowEvents = {
    onClick: (e, row) => {
      history.push({
        pathname: `${props.jsonFileName}/more`,
        state: {
          itemData: row,
        },
      });
    },
  };

  return (
    <>
      <Row className="d-flex justify-content-end px-5 py-2">
        <Col lg={6}>
          <InputGroup>
            <FormControl
              size="sm"
              className="search-wiki"
              placeholder='Search by name...'
              aria-label='search'
              aria-describedby='basic-addon1'
              onChange={(e) => { setSearchName(e.target.value); }}
            />
            <Button variant="warning" onClick={() => {
              addToast('Start typing in the search box, and you\'ll see the new results :)', { appearance: 'info' });
            }}>
              <FontAwesomeIcon icon={faSearch} />
            </Button>
          </InputGroup>
        </Col>
      </Row>
      <Row className="mx-3">
        <Col>
          <BootstrapTable noDataIndication={() => 'No data to display.'}
                          bordered={false}
                          bootstrap4={true}
                          classes='text-center table-borderless table-items-wiki'
                          keyField='id'
                          data={filteredItems}
                          columns={columns}
                          rowEvents={ tableRowEvents }
                          pagination={paginationFactory({ prePageText: 'Prev', nextPageText: 'Next', hideSizePerPage: true })}
          />
        </Col>
      </Row>
    </>
  );
};

export default ItemList;
