import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Image } from 'react-bootstrap';

import Loading from './Loading';
import MoneyLogo from '../assets/images/money.png';

const DetailedItem = (props) => {
  const [item, setItem] = useState(null);
  const history = useHistory();

  useEffect(() => {
    if (props.location.state === undefined) {
      history.push({
        pathname: '/',
      });
    } else {
      // Load item from props with history
      setItem(props.location.state.itemData);
    }
  }, []);

  const getImageOfItem = (id) => {
    let zerosImgPath = '';
    if (id.length <= 5) {
      zerosImgPath = '0'.repeat(5 - id.length);
    }

    return <Image className="item-icon" src={`../assets/images/icons/${zerosImgPath}${id}.png`} />;
  };

  const displayItemIcon = () => {
    if (item) {
      return getImageOfItem(item.id);
    }
    return <div />;
  };

  if (!item) {
    return <Loading />;
  }

  return (
    <Row className="mx-3 my-3 d-flex align-items-start">
      <Col lg={4} className="container-box-wiki">
        <div className="pt-3 text-center text-uppercase" style={{ fontSize: '1.2em' }}>
          <strong>{ item.name }</strong>
          <hr className="hr-main" />
        </div>
        <div className="d-flex">
          <div className="py-3 px-1">
            { displayItemIcon() }
          </div>
          <div className="py-3 px-3" style={{ fontSize: '0.8em' }}>
            <div className="pb-1" >
              <strong>Type:</strong> { item.type }
            </div>
            <div className="pb-1" >
              <strong>Subtype:</strong> { item.subtype }
            </div>
            <div className="pb-1" >
              <strong>Level:</strong> { item.level }
            </div>
            <div className="pb-1" >
              <strong>Price:</strong> { new Intl.NumberFormat().format(item.price) } Yang
            </div>
            <div className="pb-1" >
              <strong>Wearable of:</strong> { item.wearable }
            </div>
          </div>
        </div>
      </Col>
      <Col lg={8} className="container-box-wiki">
        <div className="pt-3 text-center text-uppercase" style={{ fontSize: '1.2em' }}>
          <strong>information</strong>
          <hr className="hr-main" />
        </div>
        {item.refine !== undefined && item.refine.map((refineEntry, it) => {
          const refine = refineEntry.refine_entry;

          return (
            <div key={it} className="pt-3">
              <div className="d-flex justify-content-around">
                <div>
                  <div>
                    {new Intl.NumberFormat().format(refine.cost)} <Image src={MoneyLogo} alt="yang" />
                  </div>
                  {refine.vnum0 && refine.count0 && refine.count0 !== '0' && (
                    <div>{refine.count0}x {getImageOfItem(refine.vnum0)}</div>
                  )}
                  {refine.vnum1 && refine.count1 && refine.count1 !== '0' && (
                    <div>{refine.count1}x {getImageOfItem(refine.vnum1)}</div>
                  )}
                  {refine.vnum2 && refine.count2 && refine.count2 !== '0' && (
                    <div>{refine.count2}x {getImageOfItem(refine.vnum2)}</div>
                  )}
                  {refine.vnum3 && refine.vnum3 && refine.vnum3 !== '0' && (
                    <div>{refine.vnum3}x {getImageOfItem(refine.vnum3)}</div>
                  )}
                  {refine.vnum4 && refine.vnum4 && refine.vnum4 !== '0' && (
                    <div>{refine.vnum4}x {getImageOfItem(refine.vnum4)}</div>
                  )}
                </div>
                <div>
                  <div className="item_tooltip_container">
                    <div className="item-tooltip-title">{refineEntry.result_name}</div>
                    <div>
                      From Level: {refineEntry.result_level}
                    </div>
                    {item.wearable && (
                      <div className="item-tooltip-default">
                        <div>[ Wearable ]</div>
                        <div>{item.wearable}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <hr className="hr-main" />
            </div>
          );
        })}
      </Col>
    </Row>
  );
};

export default DetailedItem;
