import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Image } from 'react-bootstrap';

import Loading from './Loading';

const DetailedPetOrMount = (props) => {
  const [item, setItem] = useState(null);
  const history = useHistory();

  useEffect(() => {
    if (props.location.state === undefined) {
      history.push({
        pathname: '/',
      });
    } else {
      // Load item from props with history
      setItem(props.location.state.itemData);
    }
  }, []);

  const getImageOfItem = (itemPar) => {
    let zerosImgPath = '';
    if (itemPar.id.length <= 5) {
      zerosImgPath = '0'.repeat(5 - itemPar.id.length);
    }

    let itemIdCorrected = itemPar.id;
    if (itemPar.name.charAt(itemPar.name.length - 2) === '+' && itemPar.name.charAt(itemPar.name.length - 2) !== '0') {
      itemIdCorrected = `${itemPar.id.substring(0, itemPar.id.length - 1)}0`;
    }

    return <Image className="item-icon" src={`../assets/images/icons/${zerosImgPath}${itemIdCorrected}.png`} />;
  };

  const displayItemIcon = () => {
    if (item) {
      return getImageOfItem(item);
    }
    return <div />;
  };

  if (!item) {
    return <Loading />;
  }

  const secondsToDhms = (seconds) => {
    const secondsNumber = Number(seconds);
    const d = Math.floor(secondsNumber / (3600 * 24));
    const h = Math.floor((secondsNumber % (3600 * 24)) / 3600);
    const m = Math.floor((secondsNumber % 3600) / 60);
    const s = Math.floor(secondsNumber % 60);

    const dDisplay = d > 0 ? d + (d === 1 ? ' day, ' : ' days ') : '';
    const hDisplay = h > 0 ? h + (h === 1 ? ' hour, ' : ' hours ') : '';
    const mDisplay = m > 0 ? m + (m === 1 ? ' minute, ' : ' minutes ') : '';
    const sDisplay = s > 0 ? s + (s === 1 ? ' second' : ' seconds') : '';
    return dDisplay + hDisplay + mDisplay + sDisplay;
  };

  return (
        <Row className="mx-3 my-3 d-flex align-items-start">
            <Col lg={4} className="container-box-wiki">
                <div className="pt-3 text-center text-uppercase" style={{ fontSize: '1.2em' }}>
                    <strong>{ item.name }</strong>
                    <hr className="hr-main" />
                </div>
                <div className="py-3 px-1 text-center">
                    { displayItemIcon() }
                </div>
            </Col>
            <Col lg={8} className="container-box-wiki">
                <div className="pt-3 text-center text-uppercase" style={{ fontSize: '1.2em' }}>
                    <strong>reward information</strong>
                    <hr className="hr-main" />
                </div>
                <div className="py-2">
                    <strong>Duration:</strong> {secondsToDhms(item.time)}
                </div>
                {item.bonus.map((it, index) => <div key={index} className="py-2"><strong>{it.type}:</strong> {it.value}</div>)}
            </Col>
        </Row>
  );
};

export default DetailedPetOrMount;
