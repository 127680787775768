import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function Header() {
  return (
    <div className="header-wiki">
      <Navbar fixed="top" variant="dark" expand="lg" className="navbar-wiki">
        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="d-flex justify-content-around w-50 text-capitalize" justify="true">
            <Nav.Link as={Link} to="/">home</Nav.Link>
            <Nav.Link href={process.env.REACT_APP_WEBSITE_URL} target="_blank">website</Nav.Link>
            <Nav.Link href={process.env.REACT_APP_ITEM_SHOP_URL} target="_blank">item-shop</Nav.Link>
            <Nav.Link href={process.env.REACT_APP_FORUM_URL} target="_blank">forum</Nav.Link>
            <Nav.Link href={process.env.REACT_APP_DISCORD_URL} target="_blank">discord</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}

export default Header;
