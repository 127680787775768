import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

function Footer() {
  return (
    <Container fluid className="footer-wiki pb-3">
      <Row className="text-center">
        <Col>
          <div>
            ©{new Date().getFullYear()} {process.env.REACT_APP_SERVER_NAME}. All rights reserved.
          </div>
          <div>
            Created by Lehel for {process.env.REACT_APP_SERVER_NAME}.
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Footer;
