import React from 'react';
import { Link, Switch, Route } from 'react-router-dom';
import {
  Col, Container, Nav, Row,
} from 'react-bootstrap';

import Home from './Home';
import ItemList from './ItemList';
import DetailedItem from './DetailedItem';
import MonsterList from './MonsterList';
import DetailedChest from './DetailedChest';
import DetailedMonster from './DetailedMonster';
import DetailedQuest from './DetailedQuest';
import DetailedPetOrMount from './DetailedPetOrMount';

function Main() {
  return (
    <Container>
      <Row>
        <Col lg={3}>
          <div className="navigation-wiki">
            <div className="mt-3 mx-3">
              <strong>Navigation</strong>
              <hr className="hr-nav" />
            </div>
            <div>
              <Nav defaultActiveKey="home" className="flex-column mx-3 my-3">
                <Nav.Link as={Link} to="/" eventKey="home">Main page</Nav.Link>
                <Nav.Link href={process.env.REACT_APP_WEBSITE_URL} target="_blank" eventKey="website">Website</Nav.Link>
                <Nav.Link href={process.env.REACT_APP_ITEM_SHOP_URL} target="_blank" eventKey="itemshop">Item Shop</Nav.Link>
                <Nav.Link href={process.env.REACT_APP_FORUM_URL} target="_blank" eventKey="forum">Community portal</Nav.Link>
                <Nav.Link href={process.env.REACT_APP_DISCORD_URL} target="_blank" eventKey="discord">Discord</Nav.Link>
                <hr className="hr-nav" />
              </Nav>
            </div>
          </div>
        </Col>
        <Col lg={9}>
          <div className="main-wiki">
            <div className="mt-3 mx-3 text-center">
              <strong>{process.env.REACT_APP_SERVER_NAME} - Wiki</strong>
              <hr className="hr-main" />
            </div>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/weapons">
                <ItemList jsonFileName='weapons' />
              </Route>
              <Route exact path="/weapons/more" component={DetailedItem} />
              <Route exact path="/armors">
                <ItemList jsonFileName='armors' />
              </Route>
              <Route exact path="/armors/more" component={DetailedItem} />
              <Route exact path="/helmets">
                <ItemList jsonFileName='helmets' />
              </Route>
              <Route exact path="/helmets/more" component={DetailedItem} />
              <Route exact path="/bracelets">
                <ItemList jsonFileName='bracelets' />
              </Route>
              <Route exact path="/bracelets/more" component={DetailedItem} />
              <Route exact path="/shields">
                <ItemList jsonFileName='shields' />
              </Route>
              <Route exact path="/shields/more" component={DetailedItem} />
              <Route exact path="/earrings">
                <ItemList jsonFileName='earrings' />
              </Route>
              <Route exact path="/earrings/more" component={DetailedItem} />
              <Route exact path="/necklaces">
                <ItemList jsonFileName='necklaces' />
              </Route>
              <Route exact path="/necklaces/more" component={DetailedItem} />
              <Route exact path="/boots">
                <ItemList jsonFileName='boots' />
              </Route>
              <Route exact path="/boots/:itemId" component={DetailedItem} />
              <Route exact path="/monsters" component={MonsterList} />
               <Route exact path="/monsters/more" component={DetailedMonster} />
              <Route exact path="/chests">
                <ItemList jsonFileName='chests' />
              </Route>
              <Route exact path="/chests/more" component={DetailedChest} />
              <Route exact path="/quests">
                <ItemList jsonFileName='quests' />
              </Route>
              <Route exact path="/quests/more" component={DetailedQuest} />
              <Route exact path="/pets">
                <ItemList jsonFileName='pets' />
              </Route>
              <Route exact path="/pets/more" component={DetailedPetOrMount} />
              <Route exact path="/mounts">
                <ItemList jsonFileName='mounts' />
              </Route>
              <Route exact path="/mounts/more" component={DetailedPetOrMount} />
            </Switch>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Main;
