import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Image } from 'react-bootstrap';

import Loading from './Loading';

const DetailedMonster = (props) => {
  const [item, setItem] = useState(null);
  const history = useHistory();

  useEffect(() => {
    if (props.location.state === undefined) {
      history.push({
        pathname: '/',
      });
    } else {
      // Load item from props with history
      setItem(props.location.state.itemData);
    }
  }, []);

  const getImageOfItem = (itemPar) => {
    let zerosImgPath = '';
    if (itemPar.id.length <= 5) {
      zerosImgPath = '0'.repeat(5 - itemPar.id.length);
    }

    let itemIdCorrected = itemPar.id;
    if (itemPar.name.charAt(itemPar.name.length - 2) === '+' && itemPar.name.charAt(itemPar.name.length - 2) !== '0') {
      itemIdCorrected = `${itemPar.id.substring(0, itemPar.id.length - 1)}0`;
    }

    return <Image className="item-icon" src={`../assets/images/icons/${zerosImgPath}${itemIdCorrected}.png`} />;
  };

  if (!item) {
    return <Loading />;
  }

  return (
    <Row className="mx-3 my-3 d-flex align-items-start">
      <Col lg={4} className="container-box-wiki">
        <div className="pt-3 text-center text-uppercase" style={{ fontSize: '1.2em' }}>
          <strong>{ item.name }</strong>
          <hr className="hr-main" />
        </div>
      </Col>
      <Col lg={8} className="container-box-wiki">
        <div className="pt-3 text-center text-uppercase" style={{ fontSize: '1.2em' }}>
          <strong>drop information</strong>
          <hr className="hr-main" />
        </div>
        {item.drops !== undefined && item.drops.map((dropEntry, it) => (
            <div key={it} className="pt-3">
              <div className="d-flex justify-content-around">
                <div>
                  <div>{dropEntry.count}x {getImageOfItem(dropEntry)} {dropEntry.name}</div>
                </div>
              </div>
              <hr className="hr-main" />
            </div>
        ))}
      </Col>
    </Row>
  );
};

export default DetailedMonster;
