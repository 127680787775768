import React from 'react';
import { Card, CardDeck, Image } from 'react-bootstrap';

import { Link } from 'react-router-dom';
import ArmorLogo from '../assets/images/categories/armor.png';
import WeaponLogo from '../assets/images/categories/weapon.png';
import HelmetLogo from '../assets/images/categories/helmet.png';
import BraceletLogo from '../assets/images/categories/bracelet.png';
import ShieldLogo from '../assets/images/categories/shield.png';
import EarringLogo from '../assets/images/categories/earring.png';
import NecklaceLogo from '../assets/images/categories/necklace.png';
import BootLogo from '../assets/images/categories/boot.png';
import MobLogo from '../assets/images/categories/monster.png';
import ChestLogo from '../assets/images/categories/chests.png';
import QuestLogo from '../assets/images/categories/quest.png';
import PetLogo from '../assets/images/categories/pets.png';
import MountLogo from '../assets/images/categories/mounts.png';

const Home = () => (
    <CardDeck className="mx-3 my-3 text-decoration-underline" style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row' }}>
      <Card className="text-center card-wiki">
        <div className="mt-3 h-75">
          <Image src={ArmorLogo} />
        </div>
        <Card.Body>
          <Card.Title as={Link} to="/armors" className="text-uppercase">armors</Card.Title>
        </Card.Body>
      </Card>
      <Card className="text-center card-wiki">
        <div className="mt-3 h-75">
          <Image src={WeaponLogo} />
        </div>
        <Card.Body>
          <Card.Title as={Link} to="/weapons" className="text-uppercase">weapons</Card.Title>
        </Card.Body>
      </Card>
      <Card className="text-center card-wiki">
        <div className="mt-3 h-75">
          <Image src={HelmetLogo} />
        </div>
        <Card.Body>
          <Card.Title as={Link} to="/helmets" className="text-uppercase">helmets</Card.Title>
        </Card.Body>
      </Card>
      <Card className="text-center card-wiki">
        <div className="mt-3 h-75">
          <Image src={BraceletLogo} />
        </div>
        <Card.Body>
          <Card.Title as={Link} to="/bracelets" className="text-uppercase">bracelets</Card.Title>
        </Card.Body>
      </Card>
      <Card className="text-center card-wiki">
        <div className="mt-3 h-75">
          <Image src={ShieldLogo} />
        </div>
        <Card.Body>
          <Card.Title as={Link} to="/shields" className="text-uppercase">shields</Card.Title>
        </Card.Body>
      </Card>
      <Card className="text-center card-wiki">
        <div className="mt-3 h-75">
          <Image src={EarringLogo} />
        </div>
        <Card.Body>
          <Card.Title as={Link} to="/earrings" className="text-uppercase">earrings</Card.Title>
        </Card.Body>
      </Card>
      <Card className="text-center card-wiki">
        <div className="mt-3 h-75">
          <Image src={NecklaceLogo} />
        </div>
        <Card.Body>
          <Card.Title as={Link} to="/necklaces" className="text-uppercase">necklaces</Card.Title>
        </Card.Body>
      </Card>
      <Card className="text-center card-wiki">
        <div className="mt-3 h-75">
          <Image src={BootLogo} />
        </div>
        <Card.Body>
          <Card.Title as={Link} to="/boots" className="text-uppercase">boots</Card.Title>
        </Card.Body>
      </Card>
      <Card className="text-center card-wiki">
        <div className="mt-3 h-75">
          <Image src={MobLogo} />
        </div>
        <Card.Body>
          <Card.Title as={Link} to="/monsters" className="text-uppercase">monsters</Card.Title>
        </Card.Body>
      </Card>
      <Card className="text-center card-wiki">
        <div className="mt-3 h-75">
          <Image src={ChestLogo} />
        </div>
        <Card.Body>
          <Card.Title as={Link} to="/chests" className="text-uppercase">chests</Card.Title>
        </Card.Body>
      </Card>
      <Card className="text-center card-wiki">
        <div className="mt-3 h-75">
          <Image src={QuestLogo} />
        </div>
        <Card.Body>
          <Card.Title as={Link} to="/quests" className="text-uppercase">quests</Card.Title>
        </Card.Body>
      </Card>
        <Card className="text-center card-wiki">
            <div className="mt-3 h-75">
                <Image src={PetLogo} />
            </div>
            <Card.Body>
                <Card.Title as={Link} to="/pets" className="text-uppercase">pets</Card.Title>
            </Card.Body>
        </Card>
        <Card className="text-center card-wiki">
            <div className="mt-3 h-75">
                <Image src={MountLogo} />
            </div>
            <Card.Body>
                <Card.Title as={Link} to="/mounts" className="text-uppercase">mounts</Card.Title>
            </Card.Body>
        </Card>
    </CardDeck>
);

export default Home;
