import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Image } from 'react-bootstrap';

import Loading from './Loading';
import MoneyLogo from '../assets/images/money.png';
import ExpLogo from '../assets/images/exp.jpg';

const DetailedQuest = (props) => {
  const [item, setItem] = useState(null);
  const history = useHistory();

  useEffect(() => {
    if (props.location.state === undefined) {
      history.push({
        pathname: '/',
      });
    } else {
      // Load item from props with history
      setItem(props.location.state.itemData);
    }
  }, []);

  const getImageOfItem = (itemPar) => {
    let zerosImgPath = '';
    if (itemPar.id.length <= 5) {
      zerosImgPath = '0'.repeat(5 - itemPar.id.length);
    }

    return <Image className="item-icon" src={`../assets/images/icons/${zerosImgPath}${itemPar.id}.png`} />;
  };

  if (!item) {
    return <Loading />;
  }

  return (
    <Row className="mx-3 my-3 d-flex align-items-start">
      <Col lg={4} className="container-box-wiki">
        <div className="pt-3 text-center text-uppercase" style={{ fontSize: '1.2em' }}>
          <strong>{ item.name }</strong>
          <hr className="hr-main" />
        </div>
      </Col>
      <Col lg={8} className="container-box-wiki">
        <div className="pt-3 text-center text-uppercase" style={{ fontSize: '1.2em' }}>
          <strong>reward information</strong>
          <hr className="hr-main" />
        </div>
        <div className="py-2 text-center">
          {new Intl.NumberFormat().format(item.quest_yang)} <Image src={MoneyLogo} alt="yang" />
        </div>
        <div className="py-2 text-center">
          {new Intl.NumberFormat().format(item.quest_exp)} <Image src={ExpLogo} alt="exp" />
        </div>
        {item.item1count
        && <div className="py-2 text-center">
          {item.item1count}x {getImageOfItem(item.item1vnum)}
        </div>}
        {item.item2count
        && <div className="py-2 text-center">
          {item.item2count}x {getImageOfItem(item.item2vnum)}
        </div>}
        {item.item3count
        && <div className="py-2 text-center">
          {item.item3count}x {getImageOfItem(item.item3vnum)}
        </div>}
      </Col>
    </Row>
  );
};

export default DetailedQuest;
